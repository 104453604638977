
import { BridgeHandler } from '../mixins/bridge-handler'
import { Component, Mixins } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { AppHandler } from '../mixins/app-handler'
import { TranslationHandler } from '../mixins/translation-handler'
import { IFooter, IUser } from '@/types'
import { getUrlParam } from '@/utils/url'
import cms from '@/cms'

@Component({
  components: {},
})
export default class App extends Mixins(AppHandler, BridgeHandler, TranslationHandler) {
  @Getter themePrimaryColor!: string
  @Getter themeFooterIconColor!: string
  @Getter themeIsDark!: string
  @Getter themeText!: string
  @Getter themeRounded!: 'medium' | 'full' | 'none'
  @Getter campaignIsEnded!: boolean
  @Getter userCurrent!: IUser| null

  isLoading = true
  localeKey = ''

  get footerData () {
    return this.localeKey !== undefined ? {
      background: this.themeIsDark ? '#000' : '#fff',
      textColor: this.themeIsDark ? '#fff' : '#000',
      iconColor: this.themeIsDark ? '#000' : '#fff',
      iconBackground: this.themeFooterIconColor,
      isSquareSocial: this.themeRounded === 'none', // sets all square
      links: [
        {
          text: this.$t('footerPrivacyTitle'),
          url: this.$t('footerPrivacy'),
        },
        {
          text: this.$t('footerImprintTitle'),
          url: this.$t('footerImprint'),
        },
        {
          text: this.$t('footerTermsTitle'),
          url: this.$t('footerTerms'),
        },
      ].filter((link) => link.url && link.text),
      socialLinks: [
        { id: 'spotify', url: this.$t('footerSpotify') },
        { id: 'youtube', url: this.$t('footerYoutube') },
        { id: 'instagram', url: this.$t('footerInstagram') },
        { id: 'twitter', url: this.$t('footerTwitter') },
        { id: 'facebook', url: this.$t('footerFacebook') },
        { id: 'appleMusic', url: this.$t('footerApple') },
        { id: 'tiktok', url: this.$t('footerTiktok') },
        { id: 'discord', url: this.$t('footerDiscord') },
      ].filter((link) => link.url),
    } : null
  }

  get style () {
    return {
      fontFamily: this.$themeFontFamily,
      color: this.themeText,
      background: this.themeIsDark ? '#000' : '#fff',
    }
  }

  async created () {
    // This init procedure is somewhat complicated because
    // we're not using CMS to handle auth
    // 1. Init CMS without tracking so campaign, translations, etc is loaded
    try {
      await this.$appInit({ editor: false })
    } catch (e) {
      alert(e)
      console.log(e)
    }
    // 2. Check for user login in callback URL or storage and load the current user
    // CMS must be initialized before this so we have access to the campaign ID
    await this.onDetectToken()
    if (this.userCurrent) {
      // We provide the user info to trakcing module before init
      cms.tracking.setTrackingUser({ logged_in: true, email: this.userCurrent.email })
    } else {
      cms.tracking.setTrackingUser(null)
    }

    // 3. Init tracking module which fires the page_meta event (with logged in user info if any)
    cms.tracking.init()

    // 4. Event listener to fire virtual_pageview on route changes
    this.$router.afterEach(() => {
      cms.tracking.trackRouteChange()
    })

    if (window.parent) {
      this.$bridgeListenMessages()
    }

    this.isLoading = false

    cms.newsletter.initBanner()

    if (this.campaignIsEnded) {
      this.$router.push('/end')
    }
  }

  mounted () {
    if (this.$route.name === 'Success') {
      this.$router.push('/')
    }

    if (!this.campaignIsEnded && this.$route.name === 'End') {
      this.$router.push('/')
    }
  }

  get tokenKey (): string {
    return `${cms.campaign.app}:${cms.campaign.appId}:token`
  }

  loadToken (): string|null {
    const urlToken = getUrlParam('access_token')
    if (urlToken) {
      this.storeToken(urlToken)
      // Delete token from URL
      const url = new URL(window.location.href)
      url.searchParams.delete('access_token')
      window.history.replaceState({}, document.title, url.toString())
      return urlToken
    }
    const storageToken = localStorage.getItem(this.tokenKey)
    return storageToken || null
  }

  storeToken (token: string) {
    localStorage.setItem(this.tokenKey, token)
  }

  deleteToken (): void {
    localStorage.removeItem(this.tokenKey)
  }

  async onDetectToken () {
    const token = this.loadToken()

    if (token) {
      try {
        await this.$store.dispatch('LOAD_USER', { token })
      } catch (e) {
        this.deleteToken()
      }
    }
  }

  onLocale (locale: string) {
    this.$setLocale(locale)
    this.localeKey = locale
  }
}
